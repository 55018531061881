<template>
  <div class="container">
    <el-form :model="devForm" ref="devForm" label-width="200px" @submit.prevent="addSubmit">
      <el-form-item label="关联设备类型">
        <el-cascader
                v-model="devForm.type"
                :options="typeOptions"
                :props="{label: 'name', value: 'id' }"
                clearable
                :disabled="id ? true : false"
                @change="selectType">
        </el-cascader>
      </el-form-item>
      <el-form-item label="关联设备名称" prop="ref_dev_id">
        <el-select
                v-model="devForm.ref_dev_id"
                filterable remote reserve-keyword placeholder="支持搜索"
                :remote-method="getOtherRemote"
                :disabled="id ? true : false"
                :loading="loading"
                style="width: 300px">
          <el-option
                  v-for="item in otherOptionlist"
                  :key="item.id"
                  :label="item.name + '(' + item.room + ')'"
                  :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="连接方式">
        <el-select v-model="devForm.link_type" @change="selLinkType">
          <el-option
                  v-for="item in linkType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <template v-if="showInterface">
        <el-form-item label="关联设备接口" prop="ref_interface" >
          <el-select v-model="devForm.ref_interface">
            <el-option
                    v-for="item in refInterfaceList"
                    :key="item.name"
                    :label="item.name + '(' + item.ip + ')'"
                    :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="本设备接口" prop="dev_interface" >
          <el-select v-model="devForm.dev_interface">
            <el-option
                    v-for="item in devInterfaceList"
                    :key="item.name"
                    :label="item.name + '(' + item.ip + ')'"
                    :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <el-form-item label="备注" prop="note">
        <el-input v-model="devForm.note" placeholder="选填，如：备份机"></el-input>
      </el-form-item>
      <div style="margin-top:30px; text-align: center">
        <el-button type="primary" native-type="submit" @click.prevent="addSubmit">提交</el-button>
        <el-button @click="closePop">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: "RefdevForm",
    props: {
      //设备id
      devId: {
        type: Number
      },
      //设备所属大类
      type: {
        type: Number
      },
      //设备所属类型
      tid: {
        type: Number
      },
      //修改当前关联数据的id
      id: {
        type: Number
      },
    },
    data() {
      return {
        loading: false,
        devForm: {},
        otherOptionlist: [],
        typeOptions: [{
          value: '123',
          label: '主机',
          children: [{
            value: '333',
            label: '物理机'
          }]
        }],
        linkType: [
          {name: '直连', value: 1},
          {name: '内网交换机', value: 2},
          {name: '互联网', value: 3},
          {name: '未知', value: 0},
        ],
        //关联设备接口列表
        refInterfaceList: [],
        //本设备接口列表
        devInterfaceList: [],
        //是否显示接口信息
        showInterface: false,
      }
    },
    watch: {
      id(val, oldVal) {
        if (val != oldVal && val != null) {
          this.getMapInfo();
        }
        if (val === null) {
          this.devForm = {};
        }
      }
    },
    mounted() {
      if (this.id != null) {
        this.getMapInfo();
      }
    },
    created() {
      this.getTypeList();
    },
    methods: {
      getMapInfo() {
        this.$get(this.$api.GetDevMapInfo, {id: this.id, dev_id: this.devId}).then((res) => {
            this.devForm = res.data;
            this.selectType(res.data.type);
        });
      },
      getTypeList() {
        this.$get(this.$api.GetTypeList, {mode: 'all'}).then((res) => {
          this.typeOptions = res;
        });
      },
      closePop(param) {
        this.$emit('closePop', param);
      },
      //选中连接方式
      selLinkType(val) {
        if (val === 1) { //直连时，显示接口信息
          this.showInterface = true;
          if (this.devForm.ref_dev_id === undefined) {
            this.devForm.link_type = undefined;
            this.showInterface = false;
            this.$message.warning('请先选择关联设备');
            return false;
          }
          //显示接口列表
          this.getInterfaceList(this.devId, 1);
          this.getInterfaceList(this.devForm.ref_dev_id, 2);
        } else {
          this.showInterface = false;
        }
      },
      //获取设备接口列表
      getInterfaceList(devId, mod) {
        let params = {
          page: 1,
          limit: 50,
          dev_id: devId
        }
        this.$get(this.$api.GetInterfaceList, params).then((res) => {
          if (mod == 1) {
            this.devInterfaceList = res.data.list;
          } else {
            this.refInterfaceList = res.data.list;
          }
        });
      },
      resetForm() {
        this.devForm = {
          type: [],
          ref_dev_id: '',
          link_type: '',
          note: ''
        };
        if (this.$refs["devForm"]) {
          this.$refs["devForm"].resetFields();
        }
      },
      clear() {
        this.devForm = {};
      },
      getOtherRemote(query) {
        if (query !== '') {
          this.loading = true;
          let cate = this.devForm.type;
          let params = {
            keys: query,
            type: cate[0],
            type_id: cate[1]
          }
          //console.log(this.devForm.type);
          this.$get(this.$api.GetDeviceOption, params).then((res) => {
            this.loading = false;
            if (res.code === 0) {
              this.otherOptionlist = res.data;
            } else {
              this.$message.error(res.message)
            }
          });
        } else {
          this.otherOptionlist = [];
        }
      },
      addSubmit() {
        if (this.devForm.ref_dev_id == '') {
          this.$message.warning('请选择关联设备');
          return false;
        }
        if (this.devForm.link_type == '' && this.devForm.link_type != 0) {
          this.$message.warning('请选择链接方式');
          return false;
        }
        let postData = {
          type: this.type, //当前设备大类
          dev_id: this.devId,
          ref_dev_id: this.devForm.ref_dev_id,
          link_type: this.devForm.link_type,
          note: this.devForm.note
        };
        let postUrl = this.$api.AddDevMap;
        if (this.id != null) {
          postData = {
            id: this.devForm.id,
            link_type: this.devForm.link_type,
            note: this.devForm.note
          };
          postUrl = this.$api.EditDevMap;
        }
        this.$post(postUrl, postData).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: '操作成功！',
              type: 'success'
            });
            this.closePop('refresh');
          } else {
            this.$message.error(res.message);
          }
        });
      },
      selectType(val) {
        if (val === undefined) {
          return false;
        }
        if (val.length === 0) {
          this.otherOptionlist = [];
          this.devForm.ref_dev_id = '';
          return false;
        }
        this.$get(this.$api.GetDeviceOption, {type: val[0], type_id: val[1]}).then((res) => {
          if (res.code === 0) {
            this.otherOptionlist = res.data;
          } else {
            this.$message.error(res.message);
          }
        });
      }
    }
  }
</script>

<style scoped>

</style>
