<template>
  <div class="container">
    <el-table :data="devlist" v-loading="loading" element-loading-text="加载中..." style="width: 100%">
      <el-table-column label="序号" type="index" :index="pageIndex" width="50">
      </el-table-column>
      <el-table-column label="设备名称" prop="name">
      </el-table-column>
      <el-table-column label="位置">
        <template slot-scope="scope">
          {{scope.row.room}} / {{scope.row.cab}}
        </template>
      </el-table-column>
      <el-table-column label="连接方式" prop="link_type">
        <template slot-scope="scope">
          <span v-if="scope.row.link_type===1">
            直连<br/>
            {{scope.row.dev_interface}}(本机) > {{scope.row.ref_interface}}
          </span>
          <span v-else-if="scope.row.link_type===2">内网交换机</span>
          <span v-else-if="scope.row.link_type===3">互联网</span>
          <span v-else>未知</span>
        </template>
      </el-table-column>
      <el-table-column label="设备类型" prop="type">
        <template slot-scope="scope">
          {{scope.row.bname}} / {{scope.row.sname}}
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="note">
      </el-table-column>
      <el-table-column label="管理者" prop="manager">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <span class="row-opt" @click="edit(scope.row.id)">
            <el-link type="primary" icon="el-icon-document">编辑</el-link>
          </span>
          <el-divider direction="vertical"></el-divider>
          <span class="row-opt" @click="del(scope.row)">
            <el-link type="primary" icon="el-icon-delete">删除</el-link>
          </span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
      </el-pagination>
    </div>

    <el-dialog :title="popTitle" :visible.sync="addPop" :close-on-click-modal="false" @close="closePop">
      <hw-refdev-form :dev-id="devId" :tid="cid" :type="type" :id="refId" ref="devform"
                      @closePop="closePop"></hw-refdev-form>
    </el-dialog>
  </div>
</template>

<script>
  import RefdevForm from "@/components/pages/RefdevForm";

  export default {
    name: "RefDevicelist",
    components: {
      hwRefdevForm: RefdevForm,
    },
    props: {
      devId: {
        type: Number
      },
      //设备所属大类
      type: {
        type: Number
      },
      //设备所属类型
      cid: {
        type: Number
      },
    },
    data() {
      return {
        loading: true,
        devlist: [],
        currentPage: 1,
        pageSize: 10,
        total: 0,
        addPop: false,
        refId: null,
        popTitle: '添加关联设备',
      }
    },
    computed: {
      pageIndex() {
        return (this.currentPage - 1) * this.pageSize + 1;
      }
    },
    mounted() {
      this.getDevmapList();
    },
    methods: {
      //关联设备列表
      getDevmapList() {
        let params = {
          page: this.currentPage,
          limit: this.pageSize,
          dev_id: this.devId,
          type: this.type
        }
        this.$get(this.$api.GetDevMapList, params).then((res) => {
          this.loading = false;
            this.devlist = res.data.list;
            this.total = res.data.total_count;
        });
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getDevmapList()
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.currentPage = 1;
        this.getDevmapList()
      },
      del(row) {
        this.$confirm('此操作将删除该设备吗, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$post(this.$api.DeleteDevMap, {
            id: row.id,
            tid: row.tid,
            ref_tid: row.ref_tid,
            dev_id: row.dev_id
          }).then(() => {
              this.$message({
                message: '操作成功！',
                type: 'success'
              });
              this.getDevmapList();
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      edit(id) {
        this.popTitle = '编辑关联设备';
        this.addPop = true;
        this.refId = id;
      },
      showPop() {
        this.addPop = true;
      },
      closePop(param) {
        this.addPop = false;
        this.popTitle = '新增关联设备';
        this.refId = null;
        this.$refs.devform.resetForm();
        if (param == 'refresh') {
          this.getDevmapList();
        }
      },
    }
  }
</script>

<style scoped lang="less">
  .pagenation {
    margin: 20px auto;
    text-align: center;
  }
</style>
