<template>
  <div class="container">
    <el-table :data="devlist" v-loading="loading" element-loading-text="加载中..." style="width: 100%">
      <el-table-column label="序号" type="index" :index="pageIndex" width="50">
      </el-table-column>
      <el-table-column label="接口名称" prop="name">
      </el-table-column>
      <el-table-column label="IP" prop="ip">
      </el-table-column>
      <el-table-column label="MAC" prop="mac">
      </el-table-column>
      <el-table-column label="备注" prop="note">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <span class="row-opt" @click="edit(scope.row.id)">
            <el-link type="primary" icon="el-icon-document">编辑</el-link>
          </span>
          <el-divider direction="vertical"></el-divider>
          <span class="row-opt" @click="del(scope.row)">
            <el-link type="primary" icon="el-icon-delete">删除</el-link>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagenation">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
      </el-pagination>
    </div>

    <el-dialog :title="popTitle" :visible.sync="addPop" :close-on-click-modal="false" @close="closePop">
      <hw-ref-interface-form :dev-id="devId" :id="refId" ref="devform"
                      @closePop="closePop"></hw-ref-interface-form>
    </el-dialog>
  </div>
</template>

<script>
  import RefInterfaceForm from "./RefInterfaceForm";

  export default {
    name: "RefInterface",
    components: {
      hwRefInterfaceForm: RefInterfaceForm
    },
    props: {
      devId: {
        type: Number
      }
    },
    data() {
      return {
        loading: true,
        devlist: [],
        currentPage: 1,
        pageSize: 10,
        total: 0,
        addPop: false,
        refId: null,
        popTitle: '添加设备接口',
      }
    },
    computed: {
      pageIndex() {
        return (this.currentPage - 1) * this.pageSize + 1;
      }
    },
    mounted() {
      this.getInterfaceList();
    },
    methods: {
      //关联设备列表
      getInterfaceList() {
        let params = {
          page: this.currentPage,
          limit: this.pageSize,
          dev_id: this.devId,
          type: this.type
        }
        this.$get(this.$api.GetInterfaceList, params).then((res) => {
          this.loading = false;
          if (res.code === 200) {
            this.devlist = res.data.list;
            this.total = res.data.total_count;
          } else {
            this.$message.error(res.message)
          }
        });
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getInterfaceList()
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.currentPage = 1;
        this.getInterfaceList()
      },
      del(row) {
        this.$confirm('此操作将删除该设备接口吗, 是否继续?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$post(this.$api.DeleteInterface, {
            id: row.id,
            dev_id: row.dev_id
          }).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: '操作成功！',
                type: 'success'
              });
              this.getInterfaceList();
            } else {
              this.$message.error(res.message);
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      edit(id) {
        this.popTitle = '编辑设备接口';
        this.addPop = true;
        this.refId = id;
      },
      showPop() {
        this.addPop = true;
      },
      closePop(param) {
        this.addPop = false;
        this.popTitle = '新增设备接口';
        this.refId = null;
        this.$refs.devform.resetForm();
        if (param == 'refresh') {
          this.getInterfaceList();
        }
      },
    }
  }
</script>

<style scoped lang="less">
  .pagenation {
    margin: 20px auto;
    text-align: center;
  }
</style>
