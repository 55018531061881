<template>
  <div class="container">
    <el-form :model="devForm" :rules="rules" ref="devForm" label-width="200px" @submit.prevent="addSubmit('devForm')">
      <el-form-item label="接口名称" prop="name">
        <el-input v-model="devForm.name" placeholder="请输入接口号"></el-input>
      </el-form-item>
      <el-form-item label="IP" prop="ip">
        <el-input v-model="devForm.ip" placeholder="请输入IP"></el-input>
      </el-form-item>
      <el-form-item label="MAC" prop="mac">
        <el-input v-model="devForm.mac" placeholder="请输入MAC"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="note">
        <el-input v-model="devForm.note" placeholder="选填"></el-input>
      </el-form-item>
      <div style="margin-top:30px; text-align: center">
        <el-button type="primary" native-type="submit" @click.prevent="addSubmit('devForm')">提交</el-button>
        <el-button @click="closePop">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: "RefInterfaceForm",
    props: {
      //设备id
      devId: {
        type: Number
      },
      //修改当前关联数据的id
      id: {
        type: Number
      },
    },
    data() {
      return {
        loading: false,
        devForm: {},
        rules: {
          name: [
            {required: true, message: '设备名称不能为空', trigger: 'blur'},
            {min: 2, max: 30, message: '长度2-30字符', trigger: 'blur'}
          ],
          ip: [
            {required: true, message: 'IP不能为空', trigger: 'blur'}
          ],
        }
      }
    },
    watch: {
      id(val, oldVal) {
        if (val != oldVal && val != null) {
          this.getInterfaceInfo();
        }
        if (val === null) {
          this.devForm = {};
        }
      }
    },
    mounted() {
      if (this.id != null) {
        this.getInterfaceInfo();
      }
    },
    created() {

    },
    methods: {
      getInterfaceInfo() {
        this.$get(this.$api.GetInterfaceInfo, {id: this.id, dev_id: this.devId}).then((res) => {
            this.devForm = res.data;
        });
      },
      closePop(param) {
        this.$emit('closePop', param);
      },
      resetForm() {
        this.devForm = {
          name: '',
          ip: '',
          mac: '',
          note: ''
        };
        if (this.$refs["devForm"]) {
          this.$refs["devForm"].resetFields();
        }
      },
      clear() {
        this.devForm = {};
      },
      addSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let postData = this.devForm;
            postData.dev_id = this.devId;
            let postUrl = this.$api.AddInterface;
            if (this.id != null) {
              postData.id = this.id;
              postUrl = this.$api.EditInterface;
            }
            this.$post(postUrl, postData).then(() => {
              this.$message({
                message: '操作成功！',
                type: 'success'
              });
              this.closePop('refresh');
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
    }
  }
</script>

<style scoped lang="less">
  /deep/.el-input__inner{
    width: 80%;
  }
</style>
